<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          สร้างงาน SI.
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-text-field
                v-model="customerSI.documentNumber"
                color="secondary"
                name="documentNumber"
                hint="เลขที่หนังสือ"
                label="เลขที่หนังสือ"
                :rules="documentNumberRules"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-menu
                v-model="documentDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerSI.documentDate"
                    label="ลงวันที่"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    :rules="[(v) => !!v || 'Document date is required']"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="customerSI.documentDate"
                  @input="documentDateMenu = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-select
                :items="customerSICategories"
                v-model="customerSI.customerSICategory_id"
                name="customerSICategory"
                item-text="name"
                item-value="id"
                label="ประเภทงาน SI"
                color="secondary"
                required
                :rules="[(v) => !!v || 'SI Category is required']"
                item-color="grey"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customerSI.description"
                color="secondary"
                name="description"
                hint="รายละเอียดงาน"
                label="รายละเอียดงาน"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customerSI.requestBudget"
                color="secondary"
                name="requestBudget"
                hint="งบประมาณที่ขอ"
                label="งบประมาณที่ขอ"
                type="number"
                :rules="requestBudgetRules"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerSI.serviceFee"
                    color="secondary"
                    name="serviceFee"
                    hint="ค่าบริการ"
                    label="ค่าบริการ"
                    type="number"
                    :rules="serviceFeeRules"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <span>ถ้าชำระรายเดือน/รายปีให้ระบุค่าบริการต่อรอบบิล</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="paymentFormats"
                v-model="customerSI.paymentFormat"
                name="paymentFormat"
                item-text="text"
                item-value="value"
                label="รูปแบบการชำระเงิน"
                color="secondary"
                required
                :rules="[(v) => !!v || 'Payment Format is required']"
                item-color="grey"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerSI.billingCycles"
                color="secondary"
                name="billingCycles"
                hint="จำนวนรอบบิล"
                label="จำนวนรอบบิล"
                type="number"
                :rules="billingCyclesRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerSI.annualIncome"
                    color="secondary"
                    name="annualIncome"
                    hint="รายได้ต่อปี"
                    label="รายได้ต่อปี"
                    type="number"
                    :rules="annualIncomeRules"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <span
                  >ถ้าเป็นรายเดือนให้นำค่าบริการ x 12 รอบบิล (หากต่ำกว่า 12
                  รอบบิล ให้คูณจำนวนรอบบิลที่ผ่อนชำระ)</span
                >
              </v-tooltip>
            </v-col>
            <v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerSI.income"
                    color="secondary"
                    name="income"
                    hint="รายได้ที่ได้รับ"
                    label="รายได้ที่ได้รับ"
                    type="number"
                    :rules="incomeRules"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <span>ถ้าเป็นรายเดือน/รายปี ให้นำค่าบริการ x จำนวนรอบบิล</span>
              </v-tooltip>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerSI.cost"
                color="secondary"
                name="cost"
                hint="ต้นทุน"
                label="ต้นทุน"
                type="number"
                :rules="costRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customerSI.profit"
                color="secondary"
                name="profit"
                hint="กำไร"
                label="กำไร"
                type="number"
                :rules="profitRules"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerSI.profitPerCostRatio"
                color="secondary"
                name="profitPerCostRatio"
                hint="สัดส่วนกำไร (%)"
                label="สัดส่วนกำไร (%)"
                type="number"
                :rules="profitPerCostRatioRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="customerSIStatuses"
                v-model="customerSI.customerSIStatus_id"
                name="customerSIStatus"
                item-text="name"
                item-value="id"
                label="สถานะโครงการ"
                color="secondary"
                required
                :rules="[(v) => !!v || 'SI Status is required']"
                item-color="grey"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="customerSIBudgetStatuses"
                v-model="customerSI.customerSIBudgetStatus_id"
                name="customerSIBudgetStatus"
                item-text="name"
                item-value="id"
                label="สถานะงบประมาณโครงการ"
                color="secondary"
                required
                :rules="[(v) => !!v || 'SI Budget Status is required']"
                item-color="grey"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="customerSI.saleSector"
                color="secondary"
                name="saleSector"
                hint="ส่วนงานขาย"
                label="ส่วนงานขาย"
                clearable
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerSI.salePerson"
                color="secondary"
                name="salePerson"
                hint="เจ้าหน้าที่ขาย"
                label="เจ้าหน้าที่ขาย"
                clearable
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="customerSI.salePersonPhone"
                color="secondary"
                name="salePersonPhone"
                hint="เบอร์ติดต่อเจ้าหน้าที่ขาย"
                label="เบอร์ติดต่อเจ้าหน้าที่ขาย"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="projectStartDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerSI.projectStartDate"
                    label="วันเริ่มโครงการ"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    :rules="[(v) => !!v || 'Project start date is required']"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="customerSI.projectStartDate"
                  @input="projectStartDateMenu = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="projectEndDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customerSI.projectEndDate"
                    label="วันสิ้นสุดโครงการ"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    :rules="[(v) => !!v || 'Project end date is required']"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="customerSI.projectEndDate"
                  @input="projectEndDateMenu = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="customerSI.remark"
                color="secondary"
                name="remark"
                hint="หมายเหตุ"
                label="หมายเหตุ"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="save()"
          >save</v-btn
        >
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="cancel()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateCustomerSIDialog",
  props: {},
  data() {
    return {
      id: null,
      dialog: false,
      resolve: null,
      reject: null,
      customerSICategories: null,
      customerSIStatuses: null,
      customerSIBudgetStatuses: null,
      paymentFormats: [
        { text: "รายเดือน", value: "MONTHLY" },
        { text: "รายปี", value: "YEARLY" },
        { text: "ครั้งเดียว", value: "ONETIME" },
      ],
      customer: {
        name: null,
      },
      customerSI: {
        documentNumber: "",
        documentDate: "",
        requestBudget: 0,
        serviceFee: 0,
        paymentFormat: "MONTHLY",
        billingCycles: 0,
        annualIncome: 0,
        income: 0,
        cost: 0,
        profit: 0,
        profitPerCostRatio: 0,
        saleSector: "",
        salePerson: "",
        salePersonPhone: "",
        projectStartDate: "",
        projectEndDate: "",
        description: "",
        remark: "",
      },
      documentDateMenu: false,
      projectStartDateMenu: false,
      projectEndDateMenu: false,
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      documentNumberRules: [
        (value) => !!value || "Document number is required",
      ],
      documentDateRules: [(value) => !!value || "Document date is required"],
      requestBudgetRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Request budget has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Request budget is required",
      ],
      serviceFeeRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Service Fee has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Service fee is required",
      ],
      billingCyclesRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Billing cycles has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Billing cycles is required",
      ],
      annualIncomeRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Annual income has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Annual income is required",
      ],
      costRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Cost has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Cost price is required",
      ],
      incomeRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Income has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Income price is required",
      ],
      profitRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Profit has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Profit is required",
      ],
      profitPerCostRatioRules: [
        (value) => {
          if (!isNaN(parseFloat(value)) && value >= 0 && value <= 999999999)
            return true;
          return "Profit per cost Ratio has to be between 0 and 999,999,999";
        },
        (value) => value !== null || "Profit Per Cost Ratio is required",
      ],
    };
  },
  created() {
    this.getSICategories();
    this.getSIStatuses();
    this.getSIBudgetStatuses();
  },
  methods: {
    open: function (id) {
      this.dialog = true;
      this.id = id;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getSICategories() {
      axios.get("/customer-si-category").then((response) => {
        this.customerSICategories = response.data;
      });
    },
    getSIStatuses() {
      axios.get("/customer-si-status").then((response) => {
        this.customerSIStatuses = response.data;
      });
    },
    getSIBudgetStatuses() {
      axios.get("/customer-si-budget-status").then((response) => {
        this.customerSIBudgetStatuses = response.data;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        axios
          .post("/customer/" + this.id + "/customer-si", this.customerSI)
          .then((response) => {
            this.createCustomerSIResponse = response.data;
            if (this.createCustomerSIResponse.status === "success") {
              this.loading = false;
              this.resolve(true);
              this.dialog = false;
              this.$refs.form.reset();
              this.$emit("customer-si-created");
            }
          })
          .catch(function () {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>