<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            แก้ไขข้อมูลใบแจ้งหนี้พิเศษ
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="customerSpecialBillData.CA"
                  color="primary"
                  name="CA"
                  hint="CA"
                  label="CA"
                  type="number"
                  maxlength="10"
                  counter
                  clearable
                  :rules="customerSpecailBillDataCARules"
                  @input="customerSpecialBillData.CA === null ? customerSpecialBillData.CA = null : customerSpecialBillData.CA = customerSpecialBillData.CA.toUpperCase()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            @click="save()"
            >save</v-btn
          >
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="cancel()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "EditCustomerSpecialBillDataDialog",
  props: {},
  data() {
    return {
      id: null,
      dialog: false,
      resolve: null,
      reject: null,
      customer: {
        name: null,
      },
      customerSpecialBillData: {
        id: null,
        CA: null,
      },
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      customerSpecailBillDataCARules: [
        (value) => !!value || "CA is required",
        (value) => {
          if (value) return value.length == 7 || "CA must have 7 characters";
          else return true;
        },
      ],
    };
  },
  created() {
  },
  methods: {
    open: function(customerSpecialBillData) {
      this.dialog = true;
      this.customerSpecialBillData = JSON.parse(JSON.stringify(customerSpecialBillData));
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        delete this.customerSpecialBillData.customer_id
        axios
          .put(
            "/customer-special-bill-data/" + this.customerSpecialBillData.id,
            this.customerSpecialBillData
          )
          .then((response) => {
            this.editCustomerSpecialBillDataResponse = response.data;
            if (this.editCustomerSpecialBillDataResponse.status === "success") {
              this.loading = false;
              this.resolve(true);
              this.dialog = false;
              this.$refs.form.reset();
              this.$emit("customer-special-bill-data-edited");
            }
          })
          .catch(function() {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
