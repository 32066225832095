<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium> mdi-card-account-details </v-icon>
        ข้อมูลลูกค้า
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          :to="`/customer/${customer.id}/edit`"
        >
          <v-icon small> mdi-pencil </v-icon>
          Edit
        </v-btn>          
      </v-toolbar>
      <!-- <v-card-title>
        <v-icon medium> mdi-card-account-details </v-icon>
        ข้อมูลลูกค้า
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          :to="`/customer/${customer.id}/edit`"
        >
          <v-icon small> mdi-pencil </v-icon>
          Edit
        </v-btn>
      </v-card-title>
      <v-divider /> -->
      <v-card-text v-if="loading == false">
        <v-row>
          <v-col class="py-1" cols="5">
            <v-icon small dense color="secondary"> mdi-account </v-icon>
            <span class="secondary--text"> ชื่อลูกค้า </span>
          </v-col>
          <v-col class="py-1" cols="7">
            {{ customer.name }}
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="py-1" cols="5">
            <v-icon small dense color="secondary"> mdi-tag </v-icon>
            <span class="secondary--text"> รหัสลูกค้า (BA) </span>
          </v-col>
          <v-col class="py-1" cols="7">
            {{ customer.ba_id }}
          </v-col>
        </v-row> -->
        <v-row>
          <v-col class="py-1" cols="5">
            <v-icon small dense color="secondary"> mdi-home </v-icon>
            <span class="secondary--text"> ที่อยู่ </span>
          </v-col>
          <v-col class="py-1" cols="7">
            {{ `${customer.address}` }}<br />
            {{
              `${customer.subdistrict.district.province.id == 1 ? "" : "ต."}${
                customer.subdistrict.name_in_thai
              }`
            }}<br />
            {{
              `${customer.subdistrict.district.province.id == 1 ? "" : "อ."}${
                customer.subdistrict.district.name_in_thai
              }`
            }}<br />
            {{
              `${customer.subdistrict.district.province.id == 1 ? "" : "จ."}${
                customer.subdistrict.district.province.name_in_thai
              }`
            }}<br />
            {{ `${customer.subdistrict.zip_code}` }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1" cols="5">
            <v-icon small dense color="secondary"> mdi-phone </v-icon>
            <span class="secondary--text"> โทรศัพท์ </span>
          </v-col>
          <v-col class="py-1" cols="7">
            <span v-if="customer.phone !== null">
              <a class="blue--text" :href="`tel:${customer.phone}`">{{
                customer.phone
              }}</a>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1" cols="5">
            <v-icon small dense color="secondary"> mdi-shape </v-icon>
            <span class="secondary--text"> ประเภทลูกค้า </span>
          </v-col>
          <v-col class="py-1" cols="7">
            {{ `${customer.customerCategory.name}` }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1" cols="5">
            <v-icon small dense color="secondary"> mdi-office-building </v-icon>
            <span class="secondary--text"> ศูนย์บริการลูกค้า </span>
          </v-col>
          <v-col class="py-1" cols="7">
            {{ `${customer.officeCentre.name}` }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1" cols="5">
            <v-icon small dense color="secondary"> mdi-domain </v-icon>
            <span class="secondary--text"> ส่วนบริการลูกค้า </span>
          </v-col>
          <v-col class="py-1" cols="7">
            {{ `${customer.officeCentre.officeSector.name}` }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1" cols="5">
            <v-icon small dense color="secondary"> mdi-id-card </v-icon>
            <span class="secondary--text"> Tax ID </span>
          </v-col>
          <v-col class="py-1" cols="7">
            {{ `${customer.tax_id}` }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1" cols="12">
            <v-icon small dense color="secondary"> mdi-comment </v-icon>
            <span class="secondary--text"> หมายเหตุ </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1" cols="12">
            <v-card v-if="customer.remark !== null" outlined class="pa-2">
              {{ `${customer.remark}` }}
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
moment.locale("th");
export default {
  name: "CustomerDataCard",
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
    customer: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
};
</script>
